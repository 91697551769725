
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <h5 class="mb-3">Beneficiary Details</h5>
      <div class="row g-4">
        <div class="col-md-12">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row g-4">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center">
                    <img v-if="item.image" class="me-2 me-lg-3" @click="showProfileImg(item.image)" style="width:3rem;" :src="absoluteUrl(item.image)" />
                    <div v-else class="avatar img-thumbnail me-2 me-lg-3 flex-shrink-0">
                      <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                          {{ item.first_name ? item.first_name.charAt(0).toUpperCase() : '?'}}
                      </div>
                    </div>
                    <div>
                      <label class="d-none d-sm-block small">Full Name</label>
                      <h6 class="mb-0"> {{ getfullName }}</h6>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click.prevent="deleteItem()" class="dropdown-item" type="button">
                          Delete Beneficiary </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-sm-6 col-md-4">
                      <label class="small">First name</label>
                      <h6 class="mb-0">{{ item.first_name }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Middle name</label>
                      <h6 class="mb-0">{{ item.middle_name }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Last name</label>
                      <h6 class="mb-0">{{ item.last_name }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Phone</label>
                      <h6 class="mb-0">{{ item.mobile }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">NIN</label>
                      <h6 class="mb-0">{{ $filters.maskString(item.nin) }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">VIN</label>
                      <h6 class="mb-0">{{ $filters.maskString(item.vin) }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Gender</label>
                      <h6 class="mb-0">{{ item.gender }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Date of Birth</label>
                      <h6 class="mb-0">{{  $filters.date(item.dob) }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Address</label>
                      <h6 class="mb-0">{{item.address }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Polling Unit</label>
                      <h6 v-if="item.unit" class="mb-0">{{ item.unit.name }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Ward</label>
                      <h6 v-if="item.ward" class="mb-0">{{ item.ward.name }}</h6>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <label class="small">Local Government Area</label>
                      <h6 v-if="item.lga" class="mb-0">{{ item.lga.name }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4">
            <div class="col-12">
              <div class="card h-100 shadow-sm">
                <div class="card-header">
                  <h6 class="card-title mb-0">Bank Account Details</h6>
                </div>
                <div class="card-body">
                  <div class="row g-3">
                    <div class="col-12"><strong>Account name:</strong> <span>{{item.account_name}}</span></div>
                    <div class="col-12"><strong>Account Number:</strong> <span>{{item.account_number}}</span></div>
                    <div class="col-12"><strong>Bank:</strong> <span v-if="item.bank">{{item.bank.name}}</span></div>
                  </div>
                </div>
              </div>  
            </div>
            <div class="col-lg-6">
              <div class="card shadow-sm">
                <div class="card-header">
                  <h6 class="card-title mb-0">Verification Status</h6>
                </div>
                <div class="card-body">
                  <div  class="row g-4"> 
                    <div class="col-12">
                      <label class="small">NIN status: </label>
                      <div class="mb-0" >
                        <span v-html="statusBadge(item.nin_status)"></span>
                        <p class="mb-0 mt-1" v-if="item.nin_verified_at" > Verified At: {{ $filters.date(item.nin_verified_at) }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <label class="small">VIN status: </label>
                      <div class="mb-0" >
                        <span v-html="statusBadge(item.vin_status)"></span>
                        <p class="mb-0 mt-1" v-if="item.vin_verified_at" > Verified At: {{ $filters.date(item.vin_verified_at) }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <label class="small">Back Account status: </label>
                      <div class="mb-0" >
                        <span v-html="statusBadge(item.bank_account_status)"></span>
                        <p class="mb-0 mt-1" v-if="item.bank_account_verified_at" > Verified At: {{ $filters.date(item.bank_account_verified_at) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card shadow-sm">
                <div class="card-header">
                  <h6 class="card-title mb-0">Verification Errors</h6>
                </div>
                <div class="card-body">
                  <ol class="m-0" style="padding-left: 1rem;">
                    <li v-for="(vError, eIndex) in item.verification_errors"
                      :class="eIndex === 0 ? '': 'mt-2'"
                     :key="eIndex"> <strong>{{ vError.identity_type }}:</strong> <span class="text-danger">{{ vError.message }}</span></li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card h-100 shadow-sm">
                <div class="card-header">
                  <h6 class="card-title mb-0">Verifications</h6>
                </div>
                <div class="card-body">
                  <div class="row g-4">
                    <div v-for="(vItem, vIndex) in item.verifications"
                      class="col-lg-6"  :key="vIndex"> 
                      <div class="p-3 bg-light">
                        <p class="mb-2">Status: <span v-html="statusBadge(vItem.status)"></span></p>
                        <h6><span class="me-3">Type: {{ vItem.type }}</span> <span>Source: {{ vItem.source }}</span></h6>
                        <span v-if="vItem.program" class="">Program: {{ vItem.program.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "beneficiary-show",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      item: null,
    }
  },
  watch: {
    '$route.params.beneficiaryId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 1;
    },
    getfullName(){
      return this.item.full_name || `${this.item.first_name} ${this.item.middle_name} ${this.item.last_name}`
    }
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/beneficiaries/${this.$route.params.beneficiaryId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete beneficiary permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/beneficiaries/${this.item.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/beneficiaries'})
            }
          })
        }
      });
    },
    showProfileImg(image){
      const data = { index: 0, visible: true, files: [ this.absoluteUrl(image)] }
      this.$store.commit('TOGGLE_LIGHT_BOX', data)
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

